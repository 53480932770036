.react-datepicker {
  .react-datepicker-year-header {
    font-size: 2.0rem;
    width: 200px;
  }

  &__month &__month-text,
  &__month &__quarter-text {
    height: 4rem;
    line-height: 4rem;
    margin-left: 10px;
    margin-right: 10px;
  }

  &__day,
  &__month-text,
  &__quarter-text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    font-size: 1.4rem;
    font-weight: normal;
    line-height: initial;
    border-radius: 50%;
  }

  &__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  &__quarter-text--keyboard-selected {
    background-color: #3DA466;
    color: #FFF;

    &:focus {
      outline: 0;
    }

    &:hover {
      background-color: #3DA466;
    }
  }

  &__day--keyboard-selected:hover,
  .react-datepicker__month-text--keyboard-selected:hover,
  &__quarter-text--keyboard-selected:hover {
    background-color: #3DA466;
    color: #FFF;
  }
}