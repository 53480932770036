.react-datepicker {
  border: none;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);

  &__header {
    background-color: #fff;
    border-bottom: 0;
    padding: 0;
  }

  &__month-container {
    display: block;
    padding: 8px;
  }

  &-popper[data-placement^='bottom'] {
    margin-top: 0;
  }

  &__month {
    margin-top: 1rem;
  }

  &__current-month {
    color: #234;
    font-size: 2rem;
    margin-bottom: 8px;
  }

  &__navigation {
    top: 8px;
    width: 32px;
    height: 32px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid transparent;

    &:hover {
      border-color: #d2d7e1;
    }

    &:before {
      position: relative;
      content: '';
      display: block;
      border: 7px solid transparent;
      height: 14px;
      width: 14px;
    }

    &-icon {
      display: none;
    }
    &--previous {
      left: inherit;
      right: 48px;
      &:before {
        right: 4px;
        border-right: 7px solid #456;
      }
    }
    &--next {
      right: inherit;
      right: 12px;
      &:before {
        left: 4px;
        border-left: 7px solid #456;
      }
    }
  }

  &__today-button {
    position: absolute;
    top: 10px;
    left: 12px;
    width: auto;
    padding: 4px 16px;
    font-size: 1.2rem;
    background-color: #fff;
    border: 1px solid #d2d7e1;
    border-radius: 4px;
  }

  &__input-container {
    display: flex;
    align-items: center;

    input {
      padding: 4px 8px;
      border: 1px solid #d2d7e1;
      border-radius: 4px;
      font-size: 1.6rem;
    }
  }

  &__triangle {
    display: none;
  }

  &__close-icon {
    position: absolute;
    top: 0;
    right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    background-color: #abc;
    border-radius: 50%;
    box-sizing: border-box;
    padding: 0;
    transform: translateY(50%) rotate(45deg);
    transition-duration: 0.2s;

    &:hover {
      background-color: #789;
      cursor: pointer;
    }
    &::after,
    &::before {
      position: absolute;
      display: block;
      content: '';
      width: 8px;
      height: 2px;
      background-color: #fff;
      color: #fff;
      border-radius: 0;
      line-height: initial;
      padding: 0;
    }
    &::after {
      transform: rotate(-90deg);
    }
  }

  &__day-names,
  &__week {
    display: grid;
    grid-auto-flow: column;
  }
  &__day-name {
    &:first-child {
      color: #e94781;
    }
  }
  &__day-name,
  &__day,
  &__time-name {
    width: auto;
  }

  &__day,
  &__month-text,
  &__quarter-text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    font-size: 1.4rem;
    line-height: initial;
    border-radius: 50%;
  }

  &__day--selected,
  &__day--in-selecting-range,
  &__day--in-range,
  &__month-text--selected,
  &__month-text--in-selecting-range,
  &__month-text--in-range,
  &__quarter-text--selected,
  &__quarter-text--in-selecting-range,
  &__quarter-text--in-range {
    background-color: #3da466;
    font-weight: 600;

    &:focus {
      outline: 0;
    }

    &:hover {
      background-color: #3da466;
    }
  }
  &__day {
    &:focus {
      outline: 0;
    }

    &--today {
      border: 1px solid #3da466;
    }
  }
  &__day:hover,
  &__month-text:hover,
  &__quarter-text:hover {
    border-radius: 50%;
  }

  &__day--keyboard-selected,
  &__month-text--keyboard-selected,
  &__quarter-text--keyboard-selected {
    background-color: transparent;
    font-weight: normal;
    color: #234;
  }

  &__day--keyboard-selected:hover,
  &__month-text--keyboard-selected:hover,
  &__quarter-text--keyboard-selected:hover {
    background-color: #3da466;
    color: #fff;
  }
}
